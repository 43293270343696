import React from "react";
import { Route, Routes } from "react-router-dom";
import ProductList from "./Components/ProductList";
import Light_Dark from "./Components/Light-Dark";
import Product3dView from "./Components/Product3dView";
import SignIn from "./Components/SignIn";
import LoginProvider from "./helper/LoginContext/LoginProvider";
import SignUp from "./Components/SignUp";
import AuthRoute from "./AuthRoute";

const App = () => {
  return (
    <div className="min-h-[100vh] bg-white dark:bg-gray-900">
      <LoginProvider>
        <Routes>
          <Route path="/login" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/" element={ <AuthRoute Component={ProductList}/>} />
          <Route path="/theme" element={<AuthRoute Component={Light_Dark}/>} />
          <Route path="/productmodal" element={<AuthRoute Component={Product3dView}/>} />
        </Routes>
      </LoginProvider>
    </div>
  );
};

export default App;
