import React, { useContext, useEffect, useState } from "react";
import { Avatar, Dropdown, Navbar } from "flowbite-react";
import logo from "../../favicon.svg";
import { Moon, Sun } from "../../Assets/Svg";
import { NavLink } from "react-router-dom";
import { LoginContext } from "../../helper/LoginContext";

const Header = () => {
  const { LogoutUser } = useContext(LoginContext);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const doc = document.getElementById("root");
    toggle ? (doc.className = "dark") : (doc.className = "light");
  }, [toggle]);

  return (
    <div>
      <Navbar fluid rounded className="p-5">
        <Navbar.Brand href="https://flowbite-react.com" className="md:ps-10">
          <img
            alt="Flowbite React Logo"
            className="mr-3 h-6 sm:h-9"
            src={logo}
          />
          <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
            Flowbite React
          </span>
        </Navbar.Brand>
        <div className="flex md:order-2 align-middle md:pe-10">
          <div
            className="w-fit me-3 mt-1"
            data-testid="flowbite-tooltip-target"
          >
            <button
              onClick={() => {
                setToggle(!toggle);
              }}
              className="flex items-center rounded-lg border border-gray-200 bg-white p-2 text-xs font-medium text-gray-700 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-2 focus:ring-gray-300 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-500"
            >
              {toggle ? <Sun /> : <Moon />}
            </button>
          </div>
          <Dropdown
            inline
            label={
              <Avatar
                alt="User settings"
                img="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                rounded
              />
            }
          >
            <Dropdown.Header>
              <span className="block text-sm">Bonnie Green</span>
              <span className="block truncate text-sm font-medium">
                name@flowbite.com
              </span>
            </Dropdown.Header>
            <Dropdown.Item>Dashboard</Dropdown.Item>
            <Dropdown.Item>Settings</Dropdown.Item>
            <Dropdown.Item>Earnings</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={LogoutUser}>Sign out</Dropdown.Item>
          </Dropdown>
          <Navbar.Toggle />
        </div>
        <Navbar.Collapse>
          <NavLink
            to="/"
            className={({ isActive }) =>
              (isActive
                ? "text-cyan-700  dark:text-cyan-700"
                : "text-gray-700") + " hover:text-cyan-700 dark:text-white"
            }
          >
            <p>Home</p>
          </NavLink>
          <NavLink
            to="/theme"
            className={({ isActive }) =>
              (isActive
                ? "text-cyan-700  dark:text-cyan-700"
                : "text-gray-700") + " hover:text-cyan-700 dark:text-white"
            }
          >
            Theme
          </NavLink>
          <NavLink
            to="/productmodal"
            className={({ isActive }) =>
              (isActive
                ? "text-cyan-700  dark:text-cyan-700"
                : "text-gray-700") + " hover:text-cyan-700 dark:text-white"
            }
          >
            Product View
          </NavLink>
          <NavLink
            to="/pricing"
            className={({ isActive }) =>
              (isActive
                ? "text-cyan-700  dark:text-cyan-700"
                : "text-gray-700") + " hover:text-cyan-700 dark:text-white"
            }
          >
            Pricing
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              (isActive
                ? "text-cyan-700 dark:text-cyan-700"
                : "text-gray-700") + " hover:text-cyan-700 dark:text-white"
            }
          >
            Contact
          </NavLink>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
