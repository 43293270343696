import React, { useContext, useEffect, useState } from "react";
import { LoginContext } from "../../helper/LoginContext";
import { Button, Modal, Table } from "flowbite-react";

const Product3dView = () => {
  const { supabase } = useContext(LoginContext);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [product, setProduct] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const { data } = await supabase.from("jewel_data").select();
    setData(data);
  };

  return (
    <div className=" py-5 px-10 md:py-20 md:px-72">
      <Table className="overflow-scroll">
        <Table.Head className="text-center">
          <Table.HeadCell>Id</Table.HeadCell>
          <Table.HeadCell>Url</Table.HeadCell>
          <Table.HeadCell>Action</Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {data.map((item, index) => (
            <Table.Row className="text-center" key={index}>
              <Table.Cell>{item.id}</Table.Cell>
              <Table.Cell>{item.url}</Table.Cell>
              <Table.Cell>
               <a href="https://r2.experience.jewelry/IM-02.3dm">click</a>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Modal show={modal} size="md" popup onClose={() => setModal(false)}>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <h1 className="text-blue-700 font-bold">Product Detail</h1>
          <h5>Title :{product.id}</h5>
          <h5>Color :{product.name}</h5>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Product3dView;
