import React, { useState } from "react";
import { LoginContext } from "./index";
import { createClient } from "@supabase/supabase-js";
import { useNavigate } from "react-router-dom";

const LoginProvider = (props) => {
  const supabase = createClient(
    "https://akfgvcxwqbfwlprawjgj.supabase.co",
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFrZmd2Y3h3cWJmd2xwcmF3amdqIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODg5ODcyNjMsImV4cCI6MjAwNDU2MzI2M30.4p0MJGvRClbWSYEPMUnzPEDpHNKuA9QisCHku1uEj7w"
  );
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  // Login user though email and password
  const LoginUser = async (user) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email: user.email,
      password: user.password,
    });
    if(data){
      localStorage.setItem('user',JSON.stringify(data.user));
    }
    if (!error){navigate('/')}
  };

  // Register New User
  const RegisterNewUser = async (user) => {
    const { data, error } = await supabase.auth.signUp({
      email: user.email,
      password: user.password,
    });
    if (!error) {
      navigate("/login");
    }
    console.log(data, error, "return value");
  };

  // Logout User
  const LogoutUser = async () => {
    const { error } = await supabase.auth.signOut();
    console.log(error,'error while logout');
  };

  return (
    <LoginContext.Provider
      value={{
        supabase,
        userData,
        setUserData,
        RegisterNewUser,
        LoginUser,
        LogoutUser,
        ...props,
      }}
    >
      {props.children}
    </LoginContext.Provider>
  );
};

export default LoginProvider;
