import { Button, Table } from "flowbite-react";
import React, { useContext, useEffect, useState } from "react";
// import ProductPopup from "./ProductPopup";
import { LoginContext } from "../../helper/LoginContext";

const ProductList = () => {
  const { supabase } = useContext(LoginContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    getCountries();
  }, []);

  async function getCountries() {
    const { data } = await supabase.from("products").select();
    setData(data);
  }

  const handleOpen = (productId) => {
    const size =
      window.innerWidth < window.innerHeight
        ? window.innerWidth/2
        : window.innerHeight/2; 
    const left = window.innerWidth / 2 - size / 2;
    const top = window.innerHeight / 2 - size / 2;

    const windowFeatures = `width=${size},height=${size},left=${left},top=${top},scrollbars`;

    window.open(
      `https://3dm-loader.pages.dev/${productId}`,
      "_blank",
      windowFeatures
    );
  };
  return (
    <div className=" py-5 px-10 md:py-20 md:px-72">
      <Table className="overflow-scroll">
        <Table.Head className="text-center">
          <Table.HeadCell>Id</Table.HeadCell>
          <Table.HeadCell>Title</Table.HeadCell>
          <Table.HeadCell>Color</Table.HeadCell>
          <Table.HeadCell>Category</Table.HeadCell>
          <Table.HeadCell>Price</Table.HeadCell>
          <Table.HeadCell>Product Id</Table.HeadCell>
          <Table.HeadCell>Action</Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {data.map((item, index) => (
            <Table.Row className="text-center" key={index}>
              <Table.Cell>{item.id}</Table.Cell>
              <Table.Cell>{item.title}</Table.Cell>
              <Table.Cell>{item.color}</Table.Cell>
              <Table.Cell>{item.category}</Table.Cell>
              <Table.Cell>{item.price}</Table.Cell>
              <Table.Cell>{item.product_id}</Table.Cell>
              <Table.Cell>
                <Button
                  onClick={() => {
                    handleOpen(item.product_id);
                  }}
                  className="mx-auto"
                >
                  Product view
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {/* <ProductPopup modal={modal} item={product} setModal={setModal} /> */}
    </div>
  );
};

export default ProductList;
