import React, { useEffect } from "react";
import Header from "../Components/Header";
import { useNavigate } from "react-router-dom";

const AuthRoute = ({ Component }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const Login = localStorage.getItem("user");
    if (!Login) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      <Header />
      <Component />
    </>
  );
};

export default AuthRoute;
